//////////////////////////////////////////////
// Mixins
//////////////////////////////////////////////
$uimainbg: #ffffff;
$borderlight: #dddddd;
$topNavDividerImage: "/assets/bcdiv.png";
$topNavDividerImageFirstAct: "/assets/bcdiv.first.act.png";
$topNavDividerImageAct: "/assets/bcdiv.act.png";
$navbarbg: #f9f9f9;
$prefSlideAnimDuration: 0.4s;
$sidebarTabRadius: 3px;
$sidebarTabRadius: 1px;
$inputItemActiveBorderColor: rgba(255, 119, 0, 0.884);

//////////////////////////////////////////////
// Mixins
//////////////////////////////////////////////
@mixin fonts($size: 13px) {
  font-family: "Roboto";
  font-size: $size !important;
}

//////////////////////////////////////////////
// Main
//////////////////////////////////////////////
html,
body {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  //@include fonts(16px);
  font-family: "PT Sans";
  font-family: "Open Sans";
  font-size: 15px !important;
  height: 100vh;
  width: 100vw;
  background-color: $uimainbg;
  color: #4b4b4b;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.ci {
  font-size: 18px;
}

#app {
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  min-height: 0px;
  background-color: $uimainbg;

  #main {
    flex: 1 1 0px;
    display: flex;
    flex-direction: column;
    background-color: $uimainbg;

    /* ---------------------------------------------------- */
    /* Header */
    /* ---------------------------------------------------- */
    #header {
      flex: 0 0 auto;
      background-color: $uimainbg;
    }

    /* ---------------------------------------------------- */
    /* Structure: */
    /* AppContainer */
    /* +------ Main */
    /*         +------- header */
    /*         +------- root */
    /*                  +-------- sidebar */
    /*                  +-------- content */
    /*         +------- footer */
    /* */
    /* ---------------------------------------------------- */
    #root {
      flex: 1 1 0px;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      min-height: 0px;

      /* Right Side */
      #content {
        flex: 1 1 auto;
        overflow-y: scroll;
        padding-top: 15px;
        padding-left: 15px;
      }

      /* Left Side */
      #sidebarleft {
        padding-top: 10px;
        flex: 0 0 270px;
        display: flex;
        flex-direction: column;
        background-color: $uimainbg;
        border-right: 1px solid $borderlight;

        /* Navigation Tabs */
        ul.rctabs {
          flex: 0 0 auto;
          list-style-type: none;
          border-bottom: 1px solid #c0c0c0;
          padding: 0;
          margin: 0;

          li {
            margin-bottom: -1px;
            margin-left: 10px;
            float: left;
            border: none;
            border-bottom: none;
            background-color: $uimainbg;
            padding: 5px 15px 5px 15px;
            cursor: pointer;

            -webkit-border-top-left-radius: $sidebarTabRadius;
            -webkit-border-top-right-radius: $sidebarTabRadius;
            -moz-border-radius-topleft: $sidebarTabRadius;
            -moz-border-radius-topright: $sidebarTabRadius;
            border-top-left-radius: $sidebarTabRadius;
            border-top-right-radius: $sidebarTabRadius;
            &.active {
              background-color: #f9f9f9;
              background-image: linear-gradient(-180deg, #ffffff 0%, #f7f7f7 100%);
              border-top: 1px solid #c0c0c0;
              border-right: 1px solid #c0c0c0;
              border-left: 1px solid #c0c0c0;
              color: #1399f1;
              border-bottom: 1px solid #ebebeb;

              // FIXME
              //box-shadow: 0px 0px 10px 0px #9090905c;
              //box-shadow: -1px -2px 7px 0 #9090905c;
              a {
                color: #1399f1;
              }
            }

            a {
              color: black;
            }
          }
        }

        /*
                tab-pane and tabactive are added
                to #help & #wherepanel
                */
        .tab-pane {
          display: none !important;
          visibility: hidden !important;
        }

        .tabactive {
          display: flex !important;
          visibility: visible !important;
        }

        #help {
          flex: 1 1 0px;
          min-height: 0px;
          background-color: #ffffff;
          border-bottom: 1px solid #fafafa;
          padding: 10px 10px 0 10px;
          color: #343434;
          overflow-y: scroll;

          h6 {
            font-weight: 500;
          }
        }

        #wherepanel {
          flex: 1 1 0px;
          min-height: 0px;
          display: flex;
          flex-direction: column;

          #statustree {
            flex: 1 1 auto;
            border-bottom: 1px solid #fafafa;
            background-color: #f3f3f3;
            overflow-y: scroll;

            dl {
              dt {
                font-size: 14px;
                font-weight: bold;
                height: 32px;
                line-height: 32px;
                background-color: $uimainbg;
              }

              dd {
                border-top: 1px solid #fefefe;
                background-color: $uimainbg;
                padding: 0;
                margin: 0;
              }
            }
          }

          #statuspanel {
            flex: 0 0 200px;

            #pie {
              // Percentage display (Progress torus)
              .data {
                @include fonts(13px);
                font-weight: 500;
              }
            }

            height: 300px;
            border-top: 1px solid #e3e3e3;
            background-color: #fafafa;

            h6 {
              color: #666;
              padding: 10px 0 0 0;
              margin: 0;
              text-shadow: 1px 1px 0px #ffffff;
            }
          }
        }
      }
    }

    #footer {
      flex: 0 0 40px;
      display: flex;
      flex-direction: row;
      border-top: 1px solid $borderlight;
      align-items: center;
      color: #a0a0a0;
      @include fonts(12px);
      font-weight: 300;
      text-shadow: 1px 1px 0px #ffffff;
      background-color: $uimainbg;

      a {
        color: #a0a0a0;
        @include fonts(12px);
        font-weight: 300;
        text-shadow: 1px 1px 0px #ffffff;
      }

      #footerleft {
        // flex: 1;
        padding-left: 10px;
        justify-content: center;
        text-align: center;
      }

      span.div {
        width: 1px;
        height: 30px;
        margin: 0 10px 0 10px;
        background-color: #e0e0e0;
        box-shadow: -1px 0px 0px #fff;
      }

      #footerright {
        flex: 1 1 auto;
        flex-flow: row;
        justify-content: right;
        img {
          margin-left: 20px;
        }
      }

      #footermiddle {
        flex: 3;
        justify-content: center;
      }

      img#europeflagxs {
        width: 36px;
        margin-left: 15px;
      }
    }
  }
}

/* Navigation overrides */

.nav-tabs .nav-link {
  border-radius: 0;
  border-bottom: 1px solid red;
}

a.brand {
  color: #000000;
  font-size: 18px;
}

// Menu Toggle Button
.navbar-toggler {
  font-size: 12px;
  border-radius: 0;
}

.navbar {
  background-color: $navbarbg;
  border-bottom: 1px solid $borderlight;
}

/* Statusbar (top) */

ul#statusdisplay {
  float: left;
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid $borderlight;
  color: #656565;

  li {
    height: 32px;
    float: left;
    font-size: 14px;
    padding: 0 30px 0 20px;
    line-height: 32px;
    background: #ffffff url("#{$topNavDividerImage}") top right no-repeat;
  }

  li:last-child {
    //background: transparent url($topNavDividerImage) top left no-repeat;
    background-image: none;
  }

  li i.active {
    color: green;
    color: #218ed1;
  }

  li.active {
    background: #fafafa url("#{$topNavDividerImageAct}") top right no-repeat;
  }

  li.last {
    background: #fafafa url("#{$topNavDividerImage}") top right no-repeat;
  }

  li:first-child {
    background-color: #fafafa;
    background: #fafafa url("#{$topNavDividerImageAct}") top right no-repeat;
  }

  li.active:last-child {
    // background-color: red !important;
  }

  li.active:first-child {
    background: #fafafa url("#{$topNavDividerImageFirstAct}") top right no-repeat;
  }

  li i {
    padding-left: 5px;
    color: darkgray;
  }
}

/* Forms */

.alert,
.btn,
.input-group-addon,
select,
.form-control {
  border-radius: 0 !important;
  font-size: 13px;
}

.btn-lg {
  border-radius: 0 !important;
}

.btn-danger {
  background-color: #c82333;

  &:hover {
    background-color: #df2e3f;
  }
}

.modal-content {
  border-radius: 0 !important;
}

.mainrow {
  min-height: 100%;
}

.inlinealert {
  margin-top: 10px;
}

.alert-warning {
  border: 1px solid #d4b010;

  ul {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Help Button */

.btnhelp {
  color: green;
  font-size: 1.4em;
  cursor: help;

  &.inline {
    display: inline;
    padding: 0 5px 0 5px;
  }
}

.icnhelp {
  font-size: 18px;
}

.helpContent {
  display: none;
  visibility: hidden;
}

div#loading {
  padding: 15px;
  text-align: center;
}

/* Location screen */

.locationflag {
  width: 72px;
  border: 2px solid #e0e0e0;
}

.selected {
  border-color: #024e81;
  box-shadow: 0px 0px 12px #1399f1;
}

.countryBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.countryboxitem {
  flex: 1;
  margin-top: 10px;
}

/* General */

.txtwarning {
  color: #e00000;
}

/* Spin */

.sk-three-bounce {
  width: 40px;
  text-align: center;
}

.sk-three-bounce .sk-child {
  width: 10px;
  height: 10px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}

.sk-three-bounce .sk-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.sk-three-bounce .sk-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-three-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-three-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.chart {
  flex: 1;
}

.chart rect {
  fill: steelblue;
}

.chart text {
  fill: white;
  @include fonts(13px);
  text-anchor: end;
}

.section1 rect {
  fill: red;
}

.section2 rect {
  fill: green;
}

.btnhelp {
  //   margin-top: 3px;
  margin-bottom: 5px;
}

.helpericon {
  margin-top: 5px;
}

/* Spinner */

.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #333;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.debug {
  background-color: lightcoral !important;
}

.debug1 {
  background-color: lightblue !important;
}

.debug2 {
  background-color: lightgreen !important;
}

.debug3 {
  background-color: lightpink !important;
}

.debug4 {
  background-color: lightsalmon !important;
}

.unit {
  font-size: 11px;
  // width: 64px;
  padding: 0;
  min-width: 32px;
  text-align: center;
  //   height: 31px;
  border: 1px solid $borderlight;
  line-height: 31px;

  .trans {
    margin: 0 auto;
    padding: 0 5px 0 5px;
    text-shadow: 1px 1px #ffffff;
    min-width: 58px;
    line-height: 32px;
  }
}

@media (min-width: 1000px) {
  input + div.unit {
    border-left: 0;
  }
}

.input-group-addon {
  background-color: #e9ecef;
  background-color: #f1f5f8;
}

.tiplain {
  min-width: 60px;
}

.div {
  border-top: 1px solid #e0e0e0;
}

h3 {
  line-height: 1.35em;
}

h6 {
  line-height: 1.55em;
  color: #444;
  font-weight: normal;
}

.fitimg {
}

ul.contriblist,
ul.contribimglist {
  line-height: 4em;
}

ul.contribimglist {
  li {
    list-style-type: none;
  }
}

li.plain {
  list-style-type: none;
}

button i {
  padding-left: 9px;
}

// Highlight form errors - valueerror
input.ve {
  border: 1px solid rgb(228, 142, 142);
}

.formalert {
  line-height: 2em;
}

.show {
  -moz-transition-duration: $prefSlideAnimDuration;
  -webkit-transition-duration: $prefSlideAnimDuration;
  -o-transition-duration: $prefSlideAnimDuration;
  transition-duration: $prefSlideAnimDuration;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  max-height: 1000px;
  overflow: hidden;
}

.hide {
  overflow: hidden;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: linear;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}

.hidden {
  visibility: hidden;
  overflow: hidden;
  max-height: 0;
}

.notdisplayed {
  display: none;
}

.navbar {
  button {
    margin-right: 15px;
    background-color: #fafafa;
    color: #666;
    border-color: #a0a0a0;
    padding: 3px 10px 3px 10px;

    i {
      padding: 0;
    }
  }

  .btn-secondary:hover {
    color: #fff;
    background-color: #037af0;
  }

  .btnTogglePrefs {
    background-color: #fefefe;
    color: #777;
    padding: 3px 10px 3px 10px;

    i {
      padding: 0;
    }
  }
}

#saveproject {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  width: 100vw;
  height: 100vh;
  z-index: 2;

  // FIXME
  //background-color: #38383888;
  #savedialog {
    margin-top: 50px;
    flex: 0 0 640px;

    #title {
      height: 40px;
      line-height: 40px;
      background-color: #2b85ce;
      border: 2px solid #d0d0d0;
      border-bottom: none;
    }

    #body {
      height: 500px;
      background-color: #ffffff;
      border: 2px solid #d0d0d0;

      textarea {
        width: 100%;
        height: 100%;
        font-family: "Courier";
        font-size: 14px;
      }
    }

    #toolbar {
      background-color: #ffffff;
      height: 40px;
    }
  }
}

.formalert {
  ul {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.Select-control,
select.form-control {
  border-radius: 0px !important;
}

// Text Input Fields
.inputHeight {
  max-height: 32px;
}

// input:required {
//       border-left-color: $inputItemActiveBorderColor;;
//       border-top-color: $inputItemActiveBorderColor;;
//       border-bottom-color: $inputItemActiveBorderColor;;
//       border-right-color: rgb(194, 194, 194);
//       border-width: 1px;
// }

input:not([type="radio"]):not([type="checkbox"]),
textarea {
  &:focus {
    border-left-color: $inputItemActiveBorderColor;
    border-top-color: $inputItemActiveBorderColor;
    border-bottom-color: $inputItemActiveBorderColor;
    border-right-color: rgb(194, 194, 194);
    border-width: 1px;
  }

  &:focus + div.unit {
    border-width: 1px;
    border-right-color: $inputItemActiveBorderColor;
    border-top-color: $inputItemActiveBorderColor;
    border-bottom-color: $inputItemActiveBorderColor;
    // background-color: rgb(255, 225, 199);
    // span.trans {
    //     color: #555;
    //     text-shadow: 1px 1px 0 rgb(252, 188, 136);
    // }
  }
}

.loadingmsg {
  line-height: 2.1em;
  color: #757575;
}

span.high {
  color: rgb(121, 22, 116);

  strong {
    font-weight: bold;
  }
}

.form-check-input {
  position: initial;
}

.form-check-label {
  //   line-height: 2.5em;

  .trans {
    padding-left: 10px;
  }
}

table.rctbl {
  width: 100%;
  border: none;

  th {
    font-weight: normal;
  }

  td {
    vertical-align: middle !important;
    padding: 5px 5px 5px 5px;

    &.top {
      vertical-align: top !important;
    }

    &.symhelper {
      padding: 0 15px 0 15px;
      text-align: center;
      width: 35px;
    }

    &.bt {
      border-top: 1px solid #e0e0e0;
      padding-top: 15px;
    }

    &.tbldiv {
      width: 20px;
      text-align: center;
      padding: 0 15px 0 15px;
    }

    // Radio Cell
    &.rc {
      vertical-align: top !important;
    }

    &.radio {
      text-align: center;
      width: 35px;
    }

    &.tbllblsp {
      min-width: 480px;

      &.xs {
        width: 280px;
        white-space: normal;
      }

      &.xl {
        width: 240px;
        min-width: 240px;
        white-space: normal;
      }

      &.m {
        width: 320px;
        white-space: nowrap;
      }
    }

    &.tbllbl {
      //min-width: 480px;
      &.xs {
        width: 280px;
        white-space: normal;
      }

      &.xl {
        width: 240px;
        min-width: 240px;
        white-space: normal;
      }

      &.m {
        width: 320px;
        white-space: nowrap;
      }
    }
  }
}

table#kpitenpersp {
  input {
    min-width: 110px;
  }
}

.btnprn {
  min-width: 180px;
}

// Firefox specific
// Removes the ugly shadow around required input fields
:not(output):-moz-ui-invalid {
  box-shadow: none;
}

.example {
  border: 2px solid green;
}

// React animation test
.example-enter {
  opacity: 0.01;
  border: 2px solid red;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
  border: 2px solid red;
}

.example-exit {
  opacity: 1;
  border: 2px solid red;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.default {
  opacity: 0;
}

.appear {
  opacity: 1;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
}

.container {
}

.invalid {
  border: 1px solid #e00000;
}

span.rlabel {
  padding: 0 15px 0 10px;
}

div.input-group-addon.wide {
  // width: 110px;
}

div.input-group-addon.extrawide {
  // width: 140px;
}

#VacancyRateBefore input,
#RentIncreaseArea input {
  // width: 100px;
}

/* React select flag */
.selflag {
  width: 24px;
  height: 24px;
}

.selet-value-flag img {
  margin: 0 8px 0 0;
}

/* Vofigrid */

table.vofigrid {
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid grey;

  td {
    padding: 5px;
    text-align: center;
    min-height: 48px;
  }
}

td.lbcol.group {
  font-weight: bold;
  text-align: center;
  border-right: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  border-left: 1px solid #f3f3f3;
  border-top: 1px solid #f3f3f3;
  background-color: #e9e9e9;
}

th.lblcol,
td.lblcol.main {
  min-width: 220px;
  text-align: center;
  border-right: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  border-left: 1px solid #f3f3f3;
  border-top: 1px solid #f3f3f3;
  background-color: #f9f9f9;
}

th.unitcol,
td.unitcol {
  text-align: center;
  border-right: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  border-left: 1px solid #f3f3f3;
  border-top: 1px solid #f3f3f3;
  background-color: #f9f9f9;
  padding: 0 10px 0 10px;
}

.dbgtiny {
  font-size: 8px;
  display: block;
  color: #909090;
}

th.ctcol {
  text-align: center;
  background-color: #efefef;
  text-shadow: 1px 1px 1px #ffffff;
  color: #555;
  min-width: 32px;
  border-right: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  border-left: 1px solid #f3f3f3;
  border-top: 1px solid #f3f3f3;
}

td.valcol {
  text-align: center;
  background-color: #fcfcfc;
  border-right: 1px solid #c0c0c0;
  border-left: 1px solid #f3f3f3;
  border-bottom: 1px solid #c0c0c0;
  border-top: 1px solid #f3f3f3;
}

td.lblcol.unitcol {
  word-wrap: unset;
  border-right: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  border-left: 1px solid #f3f3f3;
  border-top: 1px solid #f3f3f3;
  background-color: #f9f9f9;
}

table#kpitenpersp {
  tr.dataRow {
    line-height: 4em;
  }
}

table#kpiinvpersp {
  tr.dataRow {
    line-height: 4em;
    border-top: 1px solid #f0f0f0;
  }
}

tr.dataRow {
  td {
    word-wrap: unset;
  }
}

.rightsidebar {
  background-color: #f0f0f0;
  overflow: scroll;
  padding: 5px 5px 0 5px;
  width: 480px;
  display: none;

  &.toggle {
    display: flex;
  }
}

#toolbar {
  padding: 5px;
}

.text-warning {
  // text-align: center;
  color: #f35d00 !important;
}

ul.addnonmon {
  list-style-type: square;

  li {
    padding: 15px 0 15px 0;
  }
}

table#printrepconf {
  td {
    padding: 10px 10px 10px 10px;
    border-bottom: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    border: 1px solid #e0e0e0;

    &.lblcol {
      background-color: #f3f3f3;
      font-weight: bold;
    }

    &.allcol {
      background-color: #f3f3f3;
    }

    &.elcol {
      background-color: #e5e5e5;
    }

    div.form-check {
      margin-bottom: 0;
    }
  }
}

#toolbar {
  padding-right: 10px;

  button {
    width: 32hipx;
  }

  button i {
    padding: 4px;
  }
}

td.ifield {
  width: 320px;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  .table {
    transform: translate(8.5in, -100%) rotate(90deg);
    transform-origin: bottom left;
    display: block;
  }
}

span.hh {
  visibility: hidden;
  display: none;
}

/* Disable number-inc-dec arrows */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:disabled {
  background-color: #f9f9f9 !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.tiny {
  font-size: 0.75em;
}

ul.errorlist {
}

a.btn.btn-primary {
  color: #ffffff;

  &:hover {
    color: #ffffff;
  }
}

span.highyes {
  font-weight: bold;
  color: green;
}

span.highno {
  font-weight: bold;
  color: #900000;
}

img.buildingthumb {
  width: 160px;
  height: 120px;
}

table#propdesc1 .selected {
  box-shadow: #999 0px 0px 20px;
}

.bsel > table > tbody > tr > td > div.bimage {
  float: left;
  padding: 0;
  margin: 0;
}

.descrow {
  background-color: #e9e9e9;
}

@media (max-width: 700px) {
  .input-group-addon {
    border: 4px solid red !important;
  }
}

.rcmode {
  font-family: "Trebuchet MS", "Gibson", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica,
    Arial, "Lucida Grande", sans-serif;
  font-size: 8px;
  text-transform: uppercase;
  color: #808080;
  padding: 0px;
  padding-left: 10px;
}

td.tinyfield {
  input {
    max-width: 90px;
  }

  .input-group {
    padding-bottom: 3px;
  }

  .unit {
    min-width: 60px;
  }
}

/*
.rcoverlaybase {
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(30, 30, 30, 0.7) !important;
  z-index: 9999;
}

.rcoverlayafteropen {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.rcolveraybeforeclose {
  opacity: 0;
}


.rccontentbase {
  -webkit-transform: scale(0.5) rotateX(-30deg);
  transform: scale(0.5) rotateX(-30deg);
  z-index: 9999;
}

.rmcontentafteropen {
  -webkit-transform: scale(1) rotateX(0deg);
  transform: scale(1) rotateX(0deg);
  transition: all 150ms ease-in;
}


.rmcontentbefclose {
  -webkit-transform: scale(0.5) rotateX(30deg);
  transform: scale(0.5) rotateX(30deg);
  transition: all 150ms ease-in;
}

.rccontentbase.modal-dialog {
  border: none;
  background-color: transparent;
  z-index: 9999;
  margin-top: 200px;
}

.modal-content {
  border-radius: 0;
}

.modal-header {
  background-color: #007bff;
  h4 {
    font-size: 16px;
    color: #ffffff;
  }

  .modal-title {
    line-height: 1em;
  }
}
 */

.ReactModal__Overlay {
  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-footer button {
  margin-left: 10px;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.ReactModal__Content {
  -webkit-transform: scale(0.5) rotateX(-30deg);
  transform: scale(0.5) rotateX(-30deg);
}

.ReactModal__Content--after-open {
  -webkit-transform: scale(1) rotateX(0deg);
  transform: scale(1) rotateX(0deg);
  transition: all 150ms ease-in;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--before-close {
  -webkit-transform: scale(0.5) rotateX(30deg);
  transform: scale(0.5) rotateX(30deg);
  transition: all 150ms ease-in;
}

.ReactModal__Content.modal-dialog {
  border: none;
  background-color: transparent;
}

.modal-content {
  border-radius: 0 !important;
  background-color: #fff;
}

.modal-header {
  background-color: #007bff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  height: 48px;
  padding-left: 10px;
  margin-bottom: 10px;

  h4 {
    font-size: 16px;
    color: #ffffff;
  }

  .modal-title {
    line-height: 1em;
  }
}

@media screen and (max-width: 600px) {
  #sidebarleft {
    display: none;
    background-color: red;
  }
}

.material-icons.resetbtn {
  float: right;
  line-height: 1.5em;
  font-size: 13px;
}

table#bundleretrofitmeasures {
  margin: 0 auto;

  td.descrow {
    vertical-align: top !important;
    height: 60px;
  }
}

td.ival {
  font-weight: bold;

  sup {
    top: -5 !important;
  }
}

#sensitivity {
  .legend {
    list-style-position: inside;
    li {
      float: left;
      font-size: 12px;
      margin-right: 18px;
    }
  }
}

/* Nav 2023 */
.navact {
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #e0e0e0;
  border-left: 5px solid #51aff3;
}

.navactind {
  border-left: 5px solid #51aff3;
}

.navinactind {
  border-left: 5px solid #e0e0e0;
}

.navinact {
  border-left: 5px solid #e0e0e0;
  background-color: rgb(248, 247, 247);
  border-bottom: 1px solid #e0e0e0;
  color: #909090;
}

.mihi {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  background-color: #f9f9f9;
}

.navcurrent {
  border-bottom: 1px solid #d2d2d2;
  background-color: #eeeeee;
  //   color: #44a2e6;
}
